import React, {Component} from 'react';
import {
	blockSettingsMapper, cafeterySettingsMapper,
	pageSettingsMapper,
	questionSettingsMapper,
	setBlockSettingsMapper
} from "../QuestionListModal";
import {FormattedMessage} from "react-intl";

class Instruction extends Component {
	render() {
		return (
			<React.Fragment>
				<div className={"d-flex align-items-center mb-3"}>
					<div>
						<p className="mb-0"><strong>{"Instrukcja"}</strong></p>
						<p className="mb-0">{"Znaczniku elementów umieszczamy w między znakami {$ $} jak {$page$} {$blok$} {$zestaw$}"}</p>
						<p className="mb-0">{"Jeśli chcemy umieścić pytanie należy wstawić typ pytania wraz z ewentualnym typem prezentacji {single} {$single:checkboxes$} {$matrix$}"}</p>
						<p className="mb-0">{"Gdy chcemy dodać jakiś element należy dodać w tej samej linii po znaczniku kolejny znacznik jak {$single:checkboxes$}{$rotateAnswers$}"}</p>
						<p className="mb-0">{"Jeśli chcemy zmienić określć typ danego elementu należy po znaku : okreśić jego wartość np {$ins:Instrukcja$}"}</p>
						<p className="mb-0">{"Aby do pytania dodać elementy kafeterii należy dodać w nowej linii między znakami {% %} dodać znaczniki takie jak {%rows%} {%columns%} w przypadku jego braku będą dodawanego odpowiedzi"}</p>
					</div>
				</div>
				<div className={"d-flex align-items-center mb-3"}>
					<div>
						<p className="mb-0"><strong>{"Przykład"}</strong></p>
						<p className="mb-0">{"{$zestaw$}"}</p>
						<p className="mb-0" style={{height: "15px"}}>{""}</p>
						<p className="mb-0">{"{$blok$}"}</p>
						<p className="mb-0" style={{height: "15px"}}>{""}</p>
						<p className="mb-0">{"{$page$}{$rotate$}"}</p>
						<p className="mb-0" style={{height: "15px"}}>{""}</p>
						<p className="mb-0">{"{$single:checkboxes$}{$rotateAnswers$}{$columns:1$}Nazwa pytania"}</p>
						<p className="mb-0">{"{$open$}{$openNotRequired$}Odpowiedź pierwsza"}</p>
						<p className="mb-0">{"{$rotateExcluded$}Odpowiedź druga"}</p>
						<p className="mb-0">{"{%excludings%}"}</p>
						<p className="mb-0">{"Odpowiedź wykluczająca"}</p>
						<p className="mb-0" style={{height: "15px"}}>{""}</p>
						<p className="mb-0">{"{$matrix:table$}{$ins:Instrukcja$}{$insMob:Instrukcja mobilna$}{$rotateColumns$}Matryca"}</p>
						<p className="mb-0">{"{%rows%}"}</p>
						<p className="mb-0">{"Wiersz 1"}</p>
						<p className="mb-0">{"{$excludeRequired$}Wiersz 2"}</p>
						<p className="mb-0">{"{%columns%}"}</p>
						<p className="mb-0">{"Kolumna 1"}</p>
						<p className="mb-0">{"{$open$}Kolumna 2"}</p>
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{$zestaw:Nazwa zestawu$}"}</strong> Rozpoczyna nowy zestaw</p>
						{Object.keys(setBlockSettingsMapper).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage id={'instructionModal.' + setBlockSettingsMapper[key]}/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{$blok:Nazwa bloku$}"}</strong> Rozpoczyna nowy blok</p>
						{Object.keys(blockSettingsMapper).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage id={'instructionModal.' + blockSettingsMapper[key]}/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{$page$}"}</strong></p>
						{Object.keys(pageSettingsMapper).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage id={'instructionModal.' + pageSettingsMapper[key]}/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className={"mb-0"}><strong>Wstawienie nowego pytania</strong></p>
						<p className="mb-0"><strong>{"{%typPytania:typPrezentacji%}"}</strong> Typ prezentacji nie jest wymagany</p>
						<p><strong>{"{%single:checkboxes"} {"{%single"}</strong> Oba zapisy są poprawne, ale czasem jest wymagany tryb prezentacji aby stworzyć odpowiednią ankietę</p>
						{Object.keys(questionSettingsMapper).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + questionSettingsMapper[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%answers%}"}</strong> Rozpoczyna dodawanie nowych odpowiedzi</p>
						{Object.keys(cafeterySettingsMapper.answers).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + cafeterySettingsMapper.answers[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%rows%}"}</strong> Rozpoczyna dodawanie nowych wierszy</p>
						{Object.keys(cafeterySettingsMapper.rows).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + cafeterySettingsMapper.rows[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%columns%}"}</strong> Rozpoczyna dodawanie nowych kolumn</p>
						{Object.keys(cafeterySettingsMapper.columns).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + cafeterySettingsMapper.columns[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%js%}"}</strong> Rozpoczyna dodawanie nowych skryptów js</p>
						{Object.keys(cafeterySettingsMapper.js).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + cafeterySettingsMapper.js[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%scalePoints%}"}</strong> Rozpoczyna dodawanie nowych punktów na skali</p>
						{Object.keys(cafeterySettingsMapper.scalePoints).map((key) => {
							return (
								<p className={"mb-0"}><strong><span>{"{$"}</span>{key}<span>{"$}"}</span></strong>
									<FormattedMessage
										id={'instructionModal.' + cafeterySettingsMapper.scalePoints[key]}
									/></p>
							)
						})}
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%excludings%}"}</strong> Rozpoczyna dodawanie nowych odpowiedzi wykluczających</p>
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%baskets%}"}</strong> Rozpoczyna dodawanie nowych koszyków</p>
					</div>
				</div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<p className="mb-0"><strong>{"{%filters%}"}</strong> Rozpoczyna dodawanie nowych filtrów</p>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Instruction;