import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Card,
  CardTitle,
  CardText,
  Badge
} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import _ from 'lodash';
import { connect } from 'react-redux';
import {addQuestions} from "scenes/Survey/Structure/data/pages/actions";
import HelpModeIconModal from "components/HelpModeIconModal";
import Instruction from '../Instruction';
import {toast} from 'react-toastify';
import {getQuestionTypes} from 'services/questionTypes';
import QuestionTypesModal from "components/QuestionTypesModal";
import QuestionInfo from "../../../../../../../../../../../../../../../../../../../../../components/QuestionInfo";
import Tooltip from "components/Tooltip";
import './styles.scss';
import Switch from "components/Switch";
import SelectQuestions from "../../../../../../../../../../../../../../../../../SelectQuestions";
import Select from "react-select";
import SelectPages from "../../../../../../../../../../../../../../../../../SelectPages";
import TextToFiltersConverter
  from "../../../../../../../../../../../../../../../../../../../../../utils/converters/textToFiltersConverter";
import elementsToStructureConverter
  from "../../../../../../../../../../../../../../../../../../../../../utils/converters/elementsToStructureConverter";

export const setBlockSettingsMapper = {
  rotate: 'rotateBlocks',
  rand: 'randBlocks',
  disabled: 'disabled',
  randThis: 'randInSurvey',
  rotateThis: 'rotateInSurvey',
  randPriority: 'randPriority'
};

export const blockSettingsMapper = {
  rotate: 'rotatePages',
  rand: 'randPages',
  disabled: 'disabled',
  displayName: 'displayName',
  randThis: 'randInSet',
  rotateThis: 'rotateInSet',
  randPriority: 'randPriority'
};

export const pageSettingsMapper = {
  rotate: 'rotateQuestions',
  rand: 'randQuestions',
  disabled: 'disabled',
  timeMin: 'timeMin',
  timeMax: 'timeMax',
  randThis: 'randInBlock',
  rotateThis: 'rotateInBlock',
  randPriority: 'randPriority'
};

export const questionSettingsMapper = {
  rotateAnswers: 'rotateAnswers',
  rotateRows: 'rotateRows',
  rotateColumns: 'rotateColumns',
  ins: 'instructionText',
  insMob: 'instructionTextMobile',
  val: 'validationText',
  valMob: "validationTextMobile",
  disabled: "disabled",
  supplementMax: "supplementMax",
  cafeteryMax: "cafeterySelectedMax",
  columns: "answersColumns",
  min: 'minValue',
  max: 'maxValue',
  dec: 'decimals',
  def: 'defaultValue',
  randThis: 'randomInPage',
  rotateThis: 'rotateInPage',
  randPriority: 'randPriority',
  reqColumns: 'requiredColumns',
  reverseColumns: 'reverseColumns',
  transposed: 'transposed',
  reverseAnswers: 'reverseAnswers',
  answerOpenAlwaysShow: 'answerOpenAlwaysShow',
  answersMax: 'answersMax',
  answersCount: 'answersCount',
  answersStart: 'answersStart',
  answersMin: 'answersMin',
  answersAddButtonCount: 'answersAddButtonCount',
  requiredCount: 'requiredCount',
  requiredAll: 'requiredAll',
  places: 'places',
  scale: 'scaleSize',
  randRows: 'randRows',
  randColumns: 'randColumns',
  columnsMin: 'columnsMin',
  columnsMax: 'columnsMax',
  rowsMin: 'rowsMin',
  rowsMax: 'rowsMax',
  css: 'css',
  videoUrl: 'video'
};
export const cafeterySettingsMapper = {
  answers: {
    open: 'isOpen',
    blocking: 'blocking',
    rotateExcluded: 'excludedFromRotate',
    min: 'minValue',
    max: 'maxValue',
    disabled: 'disabled',
    openNotRequired: 'openIsNotRequired',
    selectedCurrent: 'selectedCurrent',
    selectedMax: 'selectedMax'
  },
  rows: {
    blocking: 'blocking',
    open: 'isOpen',
    rotateExcluded: 'excludedFromRotate',
    openNotRequired: 'openIsNotRequired',
    disabled: 'disabled',
    excludeRequired: 'excludeRequired'
  },
  columns: {
    blocking: 'blocking',
    open: 'isOpen',
    rotateExcluded: 'excludedFromRotate',
    openNotRequired: 'openIsNotRequired',
    disabled: 'disabled',
    excludeRequired: 'excludeRequired'
  },
  js: {
    action: 'action'
  },
  scalePoints: {
  },
  invitation: {
  }
};

const blockingDictionary = [
  'Żadne z powyższych',
  'trudno powiedzieć',
  'odmowa',
  'żaden',
  'żadne',
];

const isOpenDictionary = [
  'Inne, jakie?',
];

const excludedFromRotateDictionary = [
  'Żadne z powyższych',
  'trudno powiedzieć',
  'odmowa',
  'żaden',
  'żadne',
  'Inne, jakie?',
];

const ShiftSelect = (props) => {
  const [value, setValue] = React.useState(props.value);

  const options = [
    {value: 0, label: 'Przed'},
    {value: 1, label: 'Po'},
  ];

  return <Select
    className="w-100"
    clearable={false}
    value={options.find(o => o.value === value)}
    options={options}
    onChange={o => {
      setValue(o.value);
      props.onChange(o.value);
    }}
  />
}

class QuestionListModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      after: props.after,
      page: props.pageId,
      position: props.position,
      loading: false,
      autopagination: props.plan.survey.pages,
      selectingQuestionType: false,
      data: [],
      filters: null,
      text: '',
      modalCloseConfirm: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleQuestionTypeModal = this.toggleQuestionTypeModal.bind(this);
    this.onChangeQuestionType = this.onChangeQuestionType.bind(this);
  }

  onChange(e) {
    let elements = e.target.value.split(/\n{2,}/).filter((text) => text.trim() !== "");
    let data = [];
    const regexSettings = /\{\$(.*?)\$\}/g;
    let filtersString = '';

    _.each(elements, element => {
      let elementData = element.split("\n").filter((text) => text.trim() !== "");
      let content = elementData[0];

      let matchAll = content.matchAll(regexSettings);
      let elementOptions = Array.from(matchAll);

      if(elementOptions.length > 0){
        let el = elementOptions[0][1].split(':');

        if(el[0] === 'invitation') {
          let invitation = {
            _type: 'invitation',
            name: content.replace("{$invitation$}", "")
          }

          data.push(invitation);
          return;
        }
        if(el[0] === 'page'){
          let page = {
            _type: 'page',
            name: el[1] || '',
          };

          elementOptions.forEach(o => {
            let x = o[1].split(':');

            let setting = this.getPageSettings(x[0]);
            if(setting !== false){
              let v = x.length > 1 ? x[1] : true;
              page[setting] = v;
            }
          })

          data.push(page);
          return;
        }

        if(el[0] === 'blok'){
          let block = {
            _type: 'block',
            name: el[1] || '',
          };

          elementOptions.forEach(o => {
            let x = o[1].split(':');

            let setting = this.getBlockSetting(x[0]);
            if(setting !== false){
              let v = x.length > 1 ? x[1] : true;
              block[setting] = v;
            }
          })

          data.push(block);
          return;
        }
        if(el[0] === 'zestaw'){
          let set = {_type: 'set', name: el[1] || ''};
          elementOptions.forEach(o => {
            let x = o[1].split(':');

            let setting = this.getSetBlockSettings(x[0]);
            if(setting !== false){
              let v = x.length > 1 ? x[1] : true;
              set[setting] = v;
            }
          })

          data.push(set);

          return;
        }
        if (el[0] === 'filters') {
          elementData.map((element) => {
            if (element !== '{$filters$}') {
              filtersString += element;
              filtersString += '\n';
            }
          })

          return;
        }
      }

      const cafeteryText = elementData.slice(1);
      let type = _.isEmpty(cafeteryText) ? 'open' : 'single';
      let presentationType;
      let settingsQuestion = {};

      elementOptions.forEach(o => {
        let option = o[1].split(':');

        if (option.length > 2 && (option[1] === 'https' || option[0] === 'http')) {
          option[1] = option[1] + ":" + option[2];
        }

        if (this.questionTypeExists(option[0])) {
          type = option[0];

          if (this.questionPresentationTypeExists(type, option[1])) {
            presentationType = option[1];
          }
        }else if(this.getQuestionSetting(type, option[0])){
          if (option[0] === 'css') {
            option[1] = o[1].slice(4);
          }

          settingsQuestion[this.getQuestionSetting(type, option[0])] = option[1] || 1;
        }

        content = content.replace(o[0], '');
      });


      let key = this.props.questionsConfig[type].cafeteryTypes[0];
      const cafetery = {[key]: []};
      _.each(cafeteryText, cafeteryTextItem => {
        if (cafeteryTextItem.startsWith('{%') && cafeteryTextItem.endsWith('%}')) {
          const forcedKey = cafeteryTextItem.substring(2, cafeteryTextItem.length - 2);

          if (this.props.questionsConfig[type].cafeteryTypes.includes(forcedKey) || forcedKey === 'js') {
            key = forcedKey;
            cafetery[key] = cafetery[key] || [];
            return;
          }
        }

        let settingsCafetery = {};
        let matchAll = cafeteryTextItem.matchAll(regexSettings);
        let cafeteryOptions = Array.from(matchAll);

        cafeteryOptions.forEach(o => {
          let option = o[1].split(':');
          if(this.getCafeterySetting(key, option[0])){
            settingsCafetery[this.getCafeterySetting(key, option[0])] = option[1] || 1;
          }

          cafeteryTextItem = cafeteryTextItem.replace(o[0], '');
        });

        if(blockingDictionary.filter(pattern => pattern.toLowerCase() === cafeteryTextItem.toLowerCase()).length > 0){
          settingsCafetery[cafeterySettingsMapper.answers.b] = 1;
        }

        if(isOpenDictionary.filter(pattern => pattern.toLowerCase() === cafeteryTextItem.toLowerCase()).length > 0){
          settingsCafetery[cafeterySettingsMapper.answers.o] = 1;
        }

        if(excludedFromRotateDictionary.filter(pattern => pattern.toLowerCase() === cafeteryTextItem.toLowerCase()).length > 0){
          settingsCafetery[cafeterySettingsMapper.answers.d] = 1;
        }

        cafetery[key].push(QuestionListModal.formatCafeteryElementData(cafeteryTextItem, type, key, settingsCafetery));
      });

      data.push({_type: 'question', content, type, presentationType, settingsQuestion, ...cafetery});
    });

    if (filtersString) {
      if (!data.find((item) => item._type === 'page')) {
        data.unshift({_type: 'page', name : ""});
      }

      if (!data.find((item) => item._type === 'block')) {
        data.unshift({_type: 'block', name : ""});
      }

      if (!data.find((item) => item._type === 'set')) {
        data.unshift({_type: 'set', name : ""});
      }

      const structure = elementsToStructureConverter(data)
      const converter = new TextToFiltersConverter(structure, filtersString);
      this.setState({filters: converter.convert()})
    }

    this.setState({ data, text: e.target.value });
  }

  static formatCafeteryElementData(text, type, key, settingsCafetery) {
    if (type === 'differential' && key === 'rows') {
      const leftContent = text.includes("|") ? text.substring(0, text.indexOf("|")) : text;
      const rightContent = text.includes("|") ? text.substr(text.indexOf("|") + 1) : null;

      return {leftContent, rightContent, settingsCafetery};
    }
    return {content: text, settingsCafetery};
  }

  onSubmit() {
    const {autopagination} = this.state;

    let page = this.state.page;
    let position = this.state.position;

    if(this.state.question){
      let pageObject = Object.values(this.props.pages).find(p => p.questions.includes(this.state.question));
      page = pageObject.id;
      position = pageObject.questions.indexOf(this.state.question) + this.state.after;
    }

    this.setState({loading: true});
    let data = {
      survey: this.props.survey.id,
      position,
      autopagination,
      filters: this.state.filters,
      elements: this.state.data.map(question => {
        question = _.merge(question, question.settingsQuestion);
        delete question.settingsQuestion;

        if(question.answers){ question.answers = question.answers.map(answer => _.omit(_.merge(answer, answer.settingsCafetery), ['settingsCafetery'])); }
        if(question.rows){ question.rows = question.rows.map(row => _.omit(_.merge(row, row.settingsCafetery), ['settingsCafetery'])); }
        if(question.columns){ question.columns = question.columns.map(column => _.omit(_.merge(column, column.settingsCafetery), ['settingsCafetery'])); }

        return question;
      })
    };

    this.props.addQuestions(page, data)
      .then(() => {
        this.setState({loading: false, data: [], text: ''});
        this.props.toggle();
      })
      .catch(e => {
        this.setState({loading: false})
        toast.error(e.response.data.errors[0].message);
      });
  }

  changeQuestionType(questionKey, type) {
    let questions = this.state.text.split(/\n{2,}/).filter((text) => text.trim() !== "");
    let question = questions[questionKey].split("\n").filter((text) => text.trim() !== "");
    let content = question[0];

    if (content.startsWith('{$')) {
      let forcedType = content.substring(1, content.indexOf('$}'));

      if (forcedType.includes(':')) {
        forcedType = forcedType.split(':')[0];
      }

      if (this.questionTypeExists(forcedType)) {
        content = content.substring(content.indexOf('$}') + 1);
      }
    }

    question[0] = '{$' + type + '$}' + content;
    questions[questionKey] = question.join("\n");
    this.onChange({target: {value: questions.join("\n\n")}})
  }

  questionTypeExists(name) {
    return !!_.find(getQuestionTypes(), questionType => questionType.type === name);
  }

  questionPresentationTypeExists(questionTypeName, presentationTypeName) {
    return !!_.find(getQuestionTypes(), questionType => questionType.name === questionTypeName + '-' + presentationTypeName);
  }

  getBlockSetting(settingName) {
    return blockSettingsMapper[settingName] || false;
  }

  getSetBlockSettings(settingName) {
    return setBlockSettingsMapper[settingName] || false;
  }

  getPageSettings(settingName) {
    return pageSettingsMapper[settingName] || false;
  }

  getQuestionSetting(type, settingName) {
    return questionSettingsMapper[settingName] || false;
  }

  getCafeterySetting(key, settingName) {
    return cafeterySettingsMapper[key][settingName] || false;
  }

  toggleQuestionTypeModal(toggle) {
    this.setState({selectingQuestionType: toggle})
  }

  onChangeQuestionType(type) {
    this.changeQuestionType(this.state.selectingQuestionType, type.name.replace('-', ':'));
    this.toggleQuestionTypeModal(false);
  }

  render() {
    const {intl, availableQuestionTypes, questionsConfig, isOpen, toggle} = this.props;
    const {loading, data, text, autopagination} = this.state;

    let questionsCount = 0;

    const elementsList = [];

    _.each(data, (element, elementKey) => {
      switch (element._type) {
        case 'block': {
          elementsList.push(<div className="d-flex justify-content-between">
            <div />
            <Badge key={elementKey} className="mb-2">Nowy blok{element.name && <span>: <i>{element.name}</i></span>}</Badge>
            <div className="pr-2">
              {element.rotatePages && <React.Fragment>
                <i id={'blok-' + elementKey + '-rotatePages'} className="fas fas fas fa-random ml-2" />
                <Tooltip id={'blok-' + elementKey + '-rotatePages'} msg={"Rotowanie kolejności"} />
              </React.Fragment>}
              {element.randPages && <React.Fragment>
                <i id={'blok-' + elementKey + '-randPages'} className="fa-solid fa-dice ml-2" />
                <Tooltip id={'blok-' + elementKey + '-randPages'} msg={"Losowanie liczby stron: " + element.randPages} />
              </React.Fragment>}
            </div>
          </div>);
          break;
        }
        case 'set': {
          elementsList.push(<Badge key={elementKey} className="mb-2">Nowy zestaw{element.name && <span>: <i>{element.name}</i></span>}</Badge>);
          break;
        }
        case 'page': {
          elementsList.push(<Badge key={elementKey} className="mb-2">Strona{element.name && <span>: <i>{element.name}</i></span>}</Badge>);
          break;
        }
        case 'question': {
          questionsCount++;
          const cafeteryList = [];
          let question = element;

          _.each(questionsConfig[question.type].cafeteryTypes, cafeteryType => {
            _.each(question[cafeteryType], (cafeteryItem, cafeteryKey) => {
              const key = 'question-' + elementKey + '-' + cafeteryType + '-' + cafeteryKey;
              let content = cafeteryKey + 1 + ': ' + cafeteryItem.content;

              if (question.type === 'differential') {
                content = cafeteryKey + 1 + ': ' + cafeteryItem.leftContent + (!!cafeteryItem.rightContent ? (' - ' + cafeteryItem.rightContent) : "")
              }

              cafeteryList.push(
                <div className="d-flex justify-content-between align-items-center hover">
                  <div key={key} className="d-block"><FormattedMessage id={"questions.buttonAddQuestionList.cafetery.label." + cafeteryType}/> {content}</div>
                  <div>
                    {_.map(cafeteryItem.settingsCafetery, (value, key) => {
                      let icon = '';
                      switch (key) {
                        case 'excludedFromRotate': {
                          icon = 'fa-random';
                          break;
                        }
                        case 'blocking': {
                          icon = 'fa-eraser';
                          break;
                        }
                        case 'isOpen': {
                          icon = 'fa-keyboard';
                          break;
                        }
                      }
                      const id = 'cs-' + elementKey + '-' + cafeteryType + '-' + cafeteryKey + '-' + key;
                      return <React.Fragment>
                        <i id={id} className={'fas ' + icon} />
                        <Tooltip placement="left" id={id} msg={intl.formatMessage({id: cafeteryType + '.'+key+'.button.tooltip'})} />
                      </React.Fragment>
                    })}
                  </div>
                </div>
              );
            })
          });

          elementsList.push(
            <Card key={elementKey} className="bg-light border border-dark mb-2 p-2">
              <CardTitle className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div id={'type-tooltip-' + elementKey}><span className="pointer mr-2" onClick={() => this.toggleQuestionTypeModal(elementKey)}><QuestionInfo question={question} /></span></div>
                  <Tooltip id={'type-tooltip-' + elementKey} msg={intl.formatMessage({id: '_.question.' + question.type + (question.presentationType ? '-' + question.presentationType : '') + '.typeName'})} />
                  <p className="text-justify mb-0">{question.content}</p>
                </div>
                {!_.isEmpty(question.settingsQuestion) && <div className="d-flex ml-4 flex-wrap">
                  {_.map(question.settingsQuestion, (sVal, sKey) => {
                    let text = '';
                    if(intl.messages['_.question.' + sKey]){
                      text = intl.messages['_.question.' + sKey];
                    }else if(intl.messages['_.question.'+question.type+'.' + sKey]){
                      text = intl.messages['_.question.'+question.type+'.' + sKey];
                    }

                    return text ? <p className="mb-0 mr-2" key={sKey}>{text}</p> : null;
                  })}
                </div>}
                {!availableQuestionTypes.includes(question.type) && <span className="text-danger small"><FormattedMessage id="questions.buttonAddQuestionList.questionList.unavailableQuestionType" /></span>}
              </CardTitle>
              <CardText>
                {cafeteryList}
              </CardText>
            </Card>
          );
          break;
        }
      }
    });

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} toggle={() => this.setState({ modalCloseConfirm: !this.state.modalCloseConfirm })} size="xxl">
          <ModalHeader toggle={() => this.setState({ modalCloseConfirm: !this.state.modalCloseConfirm })} tag="h2"><FormattedMessage id="questions.buttonAddQuestionList.cardHeader" /> <HelpModeIconModal size="xl"><Instruction /></HelpModeIconModal></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <Row>
              <Col lg={7}>
                <FormattedMessage id="questions.buttonAddQuestionList.input.placeholder">{
                  (message) => <Input
                    type="textarea"
                    name="text"
                    onChange={this.onChange}
                    value={text}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    placeholder={message}
                    rows={22}
                  />
                }</FormattedMessage>
              </Col>
              <Col lg={5} id="question-list">
                {this.state.question
                  ? <div className="d-flex mb-2">
                    <div style={{width: '115px'}}>
                      <ShiftSelect
                        value={this.state.after}
                        onChange={after => this.setState({after})}
                      />
                    </div>
                    <SelectQuestions
                      isMulti={false}
                      className="w-100 ml-2"
                      value={this.state.question}
                      onChange={questionId => {
                        this.setState({
                          question: questionId,
                        })
                      }}
                    />
                  </div>
                  : <div className="d-flex align-items-center mb-2">
                    <div className="text-nowrap">Wstaw od strony</div>
                    <SelectPages
                      className="w-100 ml-2"
                      isMulti={false}
                      value={this.state.page}
                      onChange={page => {
                        this.setState({
                          page,
                        })
                      }}
                    />
                  </div>
                }
                <Card body style={{height: 494, overflowY: 'scroll'}}>
                  {elementsList.length > 0 ? elementsList : <FormattedMessage id="questions.buttonAddQuestionList.questionList.noQuestions" />}
                </Card>
                {this.props.plan.survey.pages && <span>Włącz autostronicowanie pytań: <Switch onChange={value => this.setState({autopagination: value})} checked={autopagination} /></span>}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" onClick={() => this.setState({ modalCloseConfirm: !this.state.modalCloseConfirm })}>{message}</Button>
            }</FormattedMessage>
            <FormattedMessage id="questions.buttonAddQuestionList.button.add">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda"
                loading={loading}
                data-style={ZOOM_OUT}
                onClick={this.onSubmit}
                disabled={elementsList.length === 0}
              >
                {message} {questionsCount > 0 && <span className="ml-1">({questionsCount})</span>}
              </LaddaButton>
            }</FormattedMessage>
          </ModalFooter>
          <QuestionTypesModal isOpen={Number.isInteger(this.state.selectingQuestionType)} onSelect={this.onChangeQuestionType} toggle={() => this.toggleQuestionTypeModal(false)}/>
        </Modal>

        {this.state.modalCloseConfirm && <Modal isOpen>
          <ModalHeader>Czy na pewno zamknąć okno?</ModalHeader>
          <hr className="my-0"/>
          <ModalBody>
            <p>Czy na pewno zamknąć okno?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" className="mb-0" onClick={() => this.setState({ modalCloseConfirm: false })}>Anuluj</Button>
            <Button
              color="primary"
              onClick={() => this.props.toggle()}
            >Tak, zamknij</Button>
          </ModalFooter>
        </Modal>}
      </React.Fragment>
    )
  }
}

QuestionListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  pageId: PropTypes.number,
  position: PropTypes.number,
  questionsConfig: PropTypes.object.isRequired,
  availableQuestionTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    structure: state.survey.structure,
    survey: state.survey.structure.data.survey,
    questionsConfig: state.config.data.questions,
    plan: state.user.userPlan.plan,
    availableQuestionTypes: state.user.userPlan.plan.survey.questionsAvailable,
    questions: state.survey.structure.data.questions,
    pages: state.survey.structure.data.pages,
  }
}

export default connect(mapStateToProps, {addQuestions})(injectIntl(QuestionListModal));
