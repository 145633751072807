import {structureToElementsConverter} from "./structureToElementsConverter";

const ALLOWED_COND_TYPES = ['selected', 'notSelected', 'equals', 'notEquals', 'greaterThenOrEquals', 'lesserThenOrEquals', 'enteredText'];

export default class FiltersToTextConverter{
	constructor(structure, filters) {
		this.filters = filters;
		const elements = structureToElementsConverter(structure);
		this.sets = elements.sets;
		this.pages = elements.pages;
		this.blocks = elements.blocks;
		this.questions = elements.questions;
	}

	convert = () => {
		return this.filters.map(filter => {
			try {
				switch (filter.type) {
					case 'conditional': {
						return this.parseFilterConditional(filter);
					}
					case 'serial': {
						return this.parseFilterSerial(filter);
					}
					case 'iterative': {
						return this.parseFilterIterative(filter);
					}
				}
			}catch(e){
				console.log('error', e);
				return false;
			}
		}).filter(f => f).join("\n");
	}

	parseFilterConditional = (filter) => {
		let filterText = [];
		filterText.push(`[${filter.id}]${filter.name || ''}`);

		filterText.push(this.parseFilterConditional_conds(filter));
		filterText.push(this.parseFilterConditional_actions(filter));

		return filterText.join(';');
	}

	parseFilterConditional_conds = (filter) => {
		if(filter.blocks[0].children.length > 0){
			throw new Error('filtr zawiera kilka bloków');
		}

		const block = filter.blocks[0];
		let settings = block.type == 'OR' ? '^' : '';
		if(block.minCondsToMet > 1 || block.maxCondsToMet > 0){
			settings += `${block.minCondsToMet}-${block.maxCondsToMet} `;
		}

		const conds = filter.blocks[0].condsQuestion.map(cond => {
			if(cond.required){
				throw new Error('filtr zawiera warunek obowiązkowy');
			}
			if(cond.detailType && ALLOWED_COND_TYPES.indexOf(cond.detailType) < 0){
				console.log('filtr zawiera detailType = ' + cond.detailType);
			}

			if (ALLOWED_COND_TYPES.some(entry => cond.detailType.includes(entry))) {
				switch (cond.check) {
					case 'detail': {
						let and = cond.condType == 'and' ? '&' : '';
						let selected = '';
						if (cond.detailType == 'selected' || cond.detailType == 'notSelected') {
							selected = cond.detailType == 'notSelected' ? '!' : '';
						} else {
							selected = cond.detailType;
							if (cond.value) {
								selected += '(' + cond.value + ')';
							}
						}

						const q = this.questions.find(q => q.id == cond.question);
						let cafetery = '';
						if (cond.answers && cond.answers.length > 0) {
							cafetery = cond.answers.map(a => this.getQuestionCafeteryPosition(q, 'answers', a.answer)).join(',');
						} else if (cond.details && cond.details.length > 0) {
							cafetery = cond.details.map(detail => this.getQuestionCafeteryPosition(q, 'rows', detail.row) + '-' + this.getQuestionCafeteryPosition(q, 'columns', detail.column)).join(',');
						}

						if (q) {
							return `Q${q.positionInSurvey}${and}${selected}=${cafetery}`
						} else {
							return false;
						}

						break;
					}
					case 'visibility': {
						let selected = !cond.visibility ? '!' : '';
						const q = this.questions.find(q => q.id == cond.question);

						if (q) {
							return `Q${q.positionInSurvey}${selected}`
						} else {
							return false;
						}

						break;
					}
					case 'excluding': {
						const q = this.questions.find(q => q.id == cond.question);

						if (q) {
							let cafetery = cond.excluding.excludings.map(eId => this.getQuestionCafeteryPosition(q, 'excludings', eId)).join(',');

							return `Q${q.positionInSurvey}E=${cafetery}`
						} else {
							return false;
						}

						break;
					}

				}
			}

			console.log('filtrnie został edytowany');
		}).filter(f => f).join('|');

		return `${settings}${conds}`;
	}

	parseFilterConditional_actions = (filter) => {
		return filter.actions.map(action => {

			switch (action.actionType) {
				case 'actionJump': {
					switch(action.type){
						case 'question': {
							const q = this.questions.find(q => q.id == action.question);
							return q ? `JUMP=Q${q.positionInSurvey}` : false;
						}
						case 'page': {
							const p = this.pages.find(p => p.id == action.page);
							return p ? `JUMP=P${p.positionInSurvey}` : false;
						}
						case 'block': {
							const b = this.blocks.find(b => b.id == action.block);
							return b ? `JUMP=B${b.positionInSurvey}` : false;
						}
						case 'set': {
							const s = this.sets.find(s => s.id == action.set);
							return s ? `JUMP=S${s.positionInSurvey}` : false;
						}
						case 'surveyEnd': {
							return action.status == 4 ? `SO` : 'END';
						}
						default: {
							throw new Error('Nie obsługiwany typ akcji');
						}
					}
					break;
				}
				case 'actionVisibility': {
					let show = action.visibility ? '' : '!';
					switch (action.type) {
						case 'questions': {
							let questions = action.questions.map(qId => this.questions.find(q => q.id == qId).positionInSurvey).join(',');
							return `Q${show}=${questions}`;
						}
						case 'pages': {
							let pages = action.pages.map(id => this.pages.find(p => p.id == id).positionInSurvey).join(',');
							return `P${show}=${pages}`;
						}
						case 'blocks': {
							let blocks = action.blocks.map(id => this.blocks.find(b => b.id == id).positionInSurvey).join(',');
							return `B${show}=${blocks}`;
						}
						case 'sets': {
							let sets = action.sets.map(id => this.sets.find(s => s.id == id).positionInSurvey).join(',');
							return `S${show}=${sets}`;
						}
						case 'questionAnswers': {
							let q = this.questions.find(q => q.id == action.question);
							let cafetery = action.answers.map(aId => this.getQuestionCafeteryPosition(q, 'answers', aId)).join(',');

							return `Q${q.positionInSurvey}${show}=${cafetery}`;
						}
						case 'questionRows': {
							let q = this.questions.find(q => q.id == action.question);
							let cafetery = action.rows.map(id => this.getQuestionCafeteryPosition(q, 'rows', id)).join(',');

							return `Q${q.positionInSurvey}${show}R=${cafetery}`;
						}
						case 'questionColumns': {
							let q = this.questions.find(q => q.id == action.question);
							let cafetery = action.columns.map(id => this.getQuestionCafeteryPosition(q, 'columns', id)).join(',');

							return `Q${q.positionInSurvey}${show}C=${cafetery}`;
						}
						default: {
							throw new Error('Nie obsługiwany typ akcji');
						}
					}
					break;
				}
				default: {
					throw new Error('Nie obsługiwany typ akcji');
				}
			}

			return false;
		}).filter(a => a).join('|');
	}

	parseFilterSerial = (filter) => {
		let filterText = [];
		filterText.push(`[${filter.id}]{S}${filter.name || ''}`);

		let questionFrom = '';
		if(filter.questionFrom.question){
			const q = this.questions.find(q => q.id == filter.questionFrom.question);
			if(q){
				let hideQuestionToCondsMin = filter.hideQuestionToCondsMin > 0 ? `{${filter.hideQuestionToCondsMin}}` : '';
				let filterBy = '';
				if(filter.questionFrom.filterBy === 'rows'){
					filterBy = 'R';
				}else if(filter.questionFrom.filterBy === 'columns'){
					filterBy = 'C';
				}

				let cafetery = filter.questionFrom[filter.questionFrom.filterBy].map(cId => this.getQuestionCafeteryPosition(q, filter.questionFrom.filterBy, cId)).filter(c => c).join(',');

				questionFrom = `${hideQuestionToCondsMin}Q${q.positionInSurvey}${filterBy}=${cafetery}`;
			}
		}
		filterText.push(questionFrom);

		let questionTo = '';
		if(filter.questionTo.question){
			const q = this.questions.find(q => q.id == filter.questionTo.question);
			if(q){
				let filterBy = '';
				let action = filter.action === 2 ? '!' : '';

				if(filter.questionTo.filterBy === 'rows'){
					filterBy = 'R';
				}else if(filter.questionTo.filterBy === 'columns'){
					filterBy = 'C';
				}

				questionTo = `${action}Q${q.positionInSurvey}${filterBy}`;
			}
		}
		filterText.push(questionTo);

		return filterText.join(';');
	}

	getQuestionCafeteryPosition = (question, cafeteryType, cId) => {
		let p = question[cafeteryType].indexOf(cId)

		return p >= 0 ? p+1 : false;
	}

	getQuestionPositionInSurvey = (qId) => {
		const q = this.questions.find(q => q.id == qId);
		if(q){
			return q.positionInSurvey;
		}

		return false;
	}

	parseFilterIterative = (filter) => {
		let filterText = [];
		filterText.push(`[${filter.id}]{I}${filter.name || ''}`);

		if(filter.questionFrom.question){
			const qPosition = this.getQuestionPositionInSurvey(filter.questionFrom.question);
			if(qPosition !== false){
				filterText.push(`Q${qPosition}`)
			}
		}

		filterText.push(filter.questionsTo.map(questionTo => {
			const qPosition = this.getQuestionPositionInSurvey(questionTo.question);
			if(qPosition === false){
				return false;
			}
			let filterBy = '';
			if(questionTo.filterBy === 'rows'){
				filterBy = 'R';
			}else if(questionTo.filterBy === 'columns'){
				filterBy = 'C';
			}

			return `Q${qPosition}${filterBy}`;
		}).filter(f => f).join('|'));

		return filterText.join(';');
	}
}