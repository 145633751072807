import * as React from 'react';
import PropTypes from "prop-types";
import Tooltip from "../../../../components/Tooltip";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "../../../../components/Button";
import surveyStructureToDocxConverter from "../../../../utils/converters/surveyStructureToDocxConverter";
import api from "../../../../api";
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "../../../../reducers/planRestrictionsModals/actions";
import FiltersToTextConverter from "../../../../utils/converters/filtersToTextConverter";

function SurveyScriptButton(props) {
  const [scriptLoading, setScriptLoading] = React.useState(false);

  const getScript = async () => {
    setScriptLoading(true);
    const result = await api.survey.get.scriptExport(props.surveyId ? props.surveyId : props.structure.data.survey.id);
    let scriptText = result.script;
    if (props.filters.length > 0) {
      const converter = new FiltersToTextConverter(props.structure, props.filters);
      const filterText = "\n{$filters$}\n" + converter.convert();
      scriptText += filterText;
    }
    setScriptLoading(false)

    props.onScriptLoaded(scriptText);
  }
  
  return (
    <React.Fragment>
      {props.survey && props.plan.name === "SW Research" && <Button
          color={"primary"}
          loading={scriptLoading}
          onClick={getScript}
      >Pobierz jako skrypt</Button>}
    </React.Fragment>
  );
}

SurveyScriptButton.propTypes = {

};

function mapStateToProps(state) {
  let filters = [];
  filters = filters.concat(Object.values(state.survey.structure.data.filters));
  filters = filters.concat(Object.values(state.survey.structure.data.filterSerial));
  filters = filters.concat(Object.values(state.survey.structure.data.filterIterative));

  return {
    survey: state.survey,
    plan: state.user.userPlan.plan,
    structure: state.survey.structure,
    filters: filters
  }
}

export default connect(mapStateToProps, {})(SurveyScriptButton);